<header class="text-white dark:text-[#d4d4d4] flex-shrink-0 bg-charcoal h-12 flex items-center justify-between px-4">
  <span class="font-bold text-lg">Hellō Quickstart</span>
  <ul>
    <li> 
      <a href="https://www.hello.dev/docs/" target="_blank" class="inline-flex items-center">
        <span>Documentation</span>
        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 ml-1 opacity-80" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
        </svg>
      </a>
    </li>
  </ul>
</header>

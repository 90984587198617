<div
    class="absolute left-0 top-0 z-50 flex items-center justify-center w-full h-full"
>
    <svg
        id="spinner"
        class="text-charcoal dark:text-[#d4d4d4] animate-spin"
        style="height: 3.2rem; width: 3.2rem"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
    >
    <circle
        style="opacity: 0.5"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="3"
    />
    <path
        opacity="0.5"
        d="M2 12C2 6.47715 6.47715 2 12 2"
        stroke="black"
        stroke-width="3"
    />
    </svg>
</div>